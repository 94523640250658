<template>
    <button class="db-card-filter-btn table-filter-btn">
        <i class="lab lab-filter lab-font-size-14"></i>
        <span>{{ $t('button.filter') }}</span>
    </button>
</template>

<script>
export default {
    name: "FilterComponent.vue"
}
</script>
