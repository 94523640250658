<template>
    <button data-modal="#sidebar" class="db-table-action edit">
        <i class="lab lab-edit-line"></i>
        <span class="db-tooltip">{{ $t('button.edit') }}</span>
    </button>
</template>

<script>
export default {
    name: "SmIconSidebarModalEditComponent.vue",
}
</script>

<style scoped></style>
