import AddonComponent from "../../components/admin/addons/AddonComponent.vue";
import AddonListComponent from "../../components/admin/addons/AddonListComponent.vue";
// import ItemShowComponent from "../../components/admin/items/ItemShowComponent.vue";

export default [
    {
        path: "/admin/addons",
        component: AddonComponent,
        name: "admin.addons",
        redirect: { name: "admin.addons" },
        meta: {
            isFrontend: false,
            auth: true,
            permissionUrl: "items",
            breadcrumb: "addons",
        },
        children: [
            {
                path: "",
                component: AddonListComponent,
                name: "admin.addons",
                meta: {
                    isFrontend: false,
                    auth: true,
                    permissionUrl: "items",
                    breadcrumb: "",
                },
            },
            // {
            //     path: "show/:id",
            //     component: ItemShowComponent,
            //     name: "admin.addon.show",
            //     meta: {
            //         isFrontend: false,
            //         auth: true,
            //         permissionUrl: "items",
            //         breadcrumb: "view",
            //     },
            // }
        ],
    },
];
