<template>
    <button class="db-table-action delete">
        <i class="lab lab-delete"></i>
        <span class="db-tooltip">{{ $t('button.delete') }}</span>
    </button>
</template>

<script>
export default {
    name: "SmIconDeleteComponent.vue",
}
</script>

<style scoped></style>
