<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "TaxComponent.vue",
};
</script>

<style scoped></style>
