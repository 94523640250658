import { createI18n } from "vue-i18n";

function loadMessages() {
    const context = import.meta.glob("./languages/*.json", { eager: true });
    const messages = Object.keys(context).reduce((acc, key) => {
        const locale = key.match(/\/([a-z0-9-_]+)\.json$/i)[1];
        acc[locale] = context[key].default;
        return acc;
    }, {});
    return { messages };
}

const { messages } = loadMessages();

const i18n = createI18n({
    legacy: false,
    locale: "en",
    fallbackLocale: "en",
    messages: messages,
});

export default i18n;
