<template>
  <div :dir="direction">
    <div v-if="theme === 'frontend'">
      <FrontendNavbarComponent />
      <FrontendCartComponent />
      <router-view></router-view>
      <FrontendMobileNavBarComponent />
      <FrontendMobileAccountComponent />
      <FrontendCookiesComponent />
      <FrontendFooterComponent />
      <WhatsappSupportComponent />
    </div>

    <div v-if="theme === 'backend'">
      <main class="db-main" v-if="logged">
        <BackendNavbarComponent />
        <BackendMenuComponent />
        <router-view></router-view>
      </main>

      <div v-if="!logged">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import BackendNavbarComponent from "./layouts/backend/BackendNavbarComponent.vue";
import BackendMenuComponent from "./layouts/backend/BackendMenuComponent.vue";
import FrontendNavbarComponent from "./layouts/frontend/FrontendNavBarComponent.vue";
import FrontendFooterComponent from "./layouts/frontend/FrontendFooterComponent.vue";
import FrontendMobileNavBarComponent from "./layouts/frontend/FrontendMobileNavBarComponent.vue";
import FrontendMobileAccountComponent from "./layouts/frontend/FrontendMobileAccountComponent.vue";
import FrontendCartComponent from "./layouts/frontend/FrontendCartComponent.vue";
import FrontendCookiesComponent from "./layouts/frontend/FrontendCookiesComponent.vue";
import WhatsappSupportComponent from "./layouts/frontend/WhatsappSupportComponent.vue";
import displayModeEnum from "../enums/modules/displayModeEnum";
import env from "../config/env";


export default {
  name: "DefaultComponent",
  components: {
    FrontendCartComponent,
    FrontendMobileAccountComponent,
    FrontendMobileNavBarComponent,
    FrontendCookiesComponent,
    FrontendFooterComponent,
    FrontendNavbarComponent,
    BackendNavbarComponent,
    BackendMenuComponent,
    WhatsappSupportComponent
  },
  data() {
    return {
      theme: "frontend",
    };
  },
  computed: {
    direction: function () {
      return this.$store.getters['frontendLanguage/show'].display_mode === displayModeEnum.RTL ? 'rtl' : 'ltr';
    },
    logged: function () {
      return this.$store.getters.authStatus;
    },
  },
  beforeMount() {
    this.$store
      .dispatch("frontendSetting/lists")
      .then((res) => {
        this.$store.dispatch("globalState/init", {
          branch_id: res.data.data.site_default_branch,
          language_id: res.data.data.site_default_language,
        });
      })
      .catch();

    if (env.DEMO === "true" || env.DEMO === true || env.DEMO === "1" || env.DEMO === 1) {
      this.$store.dispatch("authcheck").then(res => {
        if (res.data.status === false) {
          this.$router.push({ name: "frontend.home" });
        };
      }).catch();
    }
  },
  watch: {
    $route(e) {
      if (e.meta.isFrontend === true) {
        this.theme = "frontend";
      } else {
        this.theme = "backend";
      }
    },
  },
};
</script>
