<template>
    <div class="col-12">
        <BreadcrumbComponent />
    </div>
    <router-view></router-view>
</template>

<script>
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";

export default {
    name: "PushNotificationComponent.vue",
    components: {
        BreadcrumbComponent,
    },
}

</script>

<style scoped></style>
